<template>
	<div>
		<el-table stripe v-loading="loading" :data="dataList">
			<el-table-column
				label="操作类型"
				prop="operType"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.operType | operTypeFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="操作数量"
				prop="number"
				min-width="140"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作对象编号"
				prop="childUserNo"
				min-width="140"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作人编号"
				prop="childUserNo"
				min-width="140"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作人名称"
				prop="childUserName"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作时间"
				prop="createTime"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作数据"
				align="center"
				class-name="small-padding fixed-width"
				width="80"
				fixed="right"
			>
				<template slot-scope="scope">
					<el-button
						size="mini"
						type="text"
						@click="handleDetail(scope.row)">
						<i class="el-icon-edit-outline"></i>
						查看
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
		<CustomForm
			ref="CustomForm"
			:on-fresh="showCustomForm"
			:isAdd="isAdd"
			@on-close="handlerCustomFormOnclose"
		></CustomForm>
	</div>
</template>

<script>
import { TerminalApi } from "@/api";
import CustomForm from "./CustomForm";
export default {
	name: "ToolBar",
	components: { CustomForm },
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [],
			showCustomForm: false,
			isAdd: false
		};
	},
	mounted() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		// 获取列表
		async getList() {
			this.loading = true;
			let result = await TerminalApi.terminalInfo.getTerminalList(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			console.log("getTerminalList----->126", result);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data.list || [];
		},
		// 详情方法
		handleDetail(row) {
			this.showCustomForm = true;
			// this.batchNo = row.batchNo;
			// 触发子组件的方法并传递ID
			this.$refs.CustomForm.getDetailListFn(row.batchNo);
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			// this.getList();
		}
	}
};
</script>

<style scoped></style>
